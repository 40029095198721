import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["email", "username", "firstName", "errorContainer"];
  static values = { locale: String };

  get csrfToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute("content");
  }

  validateEmail(event) {
    let emailErrorContainer = this.errorContainerTargets.find(
      (target) => target.dataset.errorType === "email"
    );
    if (emailErrorContainer === undefined) return;

    if (this.emailTarget.value.length == 0) return;

    fetch("/users/validate_email", {
      method: "POST",
      body: JSON.stringify({
        email: this.emailTarget.value,
        locale: this.localeValue,
      }),
      headers: { "Content-Type": "application/json", "X-CSRF-Token": this.csrfToken },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          emailErrorContainer.innerText = data.error;
          emailErrorContainer.classList.remove("hidden");
          this.emailTarget.classList.add("border-2");
          this.emailTarget.classList.add("border-highlight-red");
          this.emailTarget.classList.add("wiggle-small");
        } else {
          emailErrorContainer.classList.add("hidden");
          this.emailTarget.classList.remove("border-2");
          this.emailTarget.classList.remove("border-highlight-red");
          this.emailTarget.classList.remove("wiggle-small");
        }
      });
  }

  validateUsername(event) {
    let usernameErrorContainer = this.errorContainerTargets.find(
      (target) => target.dataset.errorType === "username"
    );
    if (usernameErrorContainer === undefined) return;

    if (this.usernameTarget.value.length == 0) return;

    document.getElementById("suggestion").classList.add("hidden");

    fetch("/users/validate_username", {
      method: "POST",
      body: JSON.stringify({
        username: this.usernameTarget.value,
        locale: this.localeValue,
      }),
      headers: { "Content-Type": "application/json", "X-CSRF-Token": this.csrfToken },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          usernameErrorContainer.innerText = data.error;
          usernameErrorContainer.classList.remove("hidden");
          this.usernameTarget.classList.add("border-2");
          this.usernameTarget.classList.add("border-highlight-red");
          this.usernameTarget.classList.add("wiggle-small");
        } else {
          usernameErrorContainer.classList.add("hidden");
          this.usernameTarget.classList.remove("border-2");
          this.usernameTarget.classList.remove("border-highlight-red");
          this.usernameTarget.classList.remove("wiggle-small");
        }
      });
  }

  validateFirstName(event) {
    let firstNameErrorContainer = this.errorContainerTargets.find(
      (target) => target.dataset.errorType === "first_name"
    );
    console.log(firstNameErrorContainer);
    if (firstNameErrorContainer === undefined) return;

    if (this.firstNameTarget.value.length == 0) return;
    console.log(this.firstNameTarget);

    fetch("/users/validate_first_name", {
      method: "POST",
      body: JSON.stringify({
        first_name: this.firstNameTarget.value,
        locale: this.localeValue,
      }),
      headers: { "Content-Type": "application/json", "X-CSRF-Token": this.csrfToken },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.error) {
          firstNameErrorContainer.innerText = data.error;
          firstNameErrorContainer.classList.remove("hidden");
          this.firstNameTarget.classList.add("border-2");
          this.firstNameTarget.classList.add("border-highlight-red");
          this.firstNameTarget.classList.add("wiggle-small");
        } else {
          firstNameErrorContainer.classList.add("hidden");
          this.firstNameTarget.classList.remove("border-2");
          this.firstNameTarget.classList.remove("border-highlight-red");
          this.firstNameTarget.classList.remove("wiggle-small");
        }
      });
  }

  async validateSubmit(event) {
    const formData = await event.detail.formSubmission;
    const { success, fetchResponse } = formData.result;
    if (success) return;

    const res = await fetchResponse.responseText;
    const { errors } = JSON.parse(res);

    this.errorContainerTargets.forEach((errorContainer) => {
      const errorType = errorContainer.dataset.errorType;
      const errorMsg = extractError({ errors, type: errorType });

      errorContainer.innerText = errorMsg || "";
    });
  }
}

function extractError({ errors, type }) {
  if (!errors || !Array.isArray(errors)) return;

  const foundError = errors.find(
    (error) => error.type.toLowerCase() === type.toLowerCase()
  );
  return foundError?.detail;
}
